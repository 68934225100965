import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ScrollContext} from 'react-router-scroll-4';
import {IntlProvider} from 'react-redux-multilingual';
import translations from './constants/translations';
import store from './store';
import {getAllProducts, signIn, signOut} from './actions';
import './index.scss';
// import 'antd/dist/antd.css';

import Layout from './components/app';
import TemplateSpecial from './components/TemplateSpecial';
import Fashion from './components/layouts/fashion/main';
import Login from './components/pages/login';
import DeleteAccount from './components/pages/delete-account';
import Register from './components/pages/register';
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";
import NoSideBar from "./components/products/no-sidebar";
import Cart from "./components/cart";
import Home from "./components/Home";
import Hashtag from "./components/Hashtag";
import CategoryLanding from "./components/CategoryLanding";
import Checkout from "./components/Checkout";
import {empty} from "./helpers/generalHelper";
import axios from "axios";
import {APP_VERSION, BRAND_CODE, BUILD_NUMBER} from "./utils/constants";
import Account from "./components/Account";
import AccountChangePassword from "./components/AccountChangePassword";
import AccountShippingAddress from "./components/AccountShippingAddress";
import PaymentResponse from "./components/PaymentResponse";
import AccountOrders from "./components/AccountOrders";
import AccountWishlist from "./components/AccountWishlist";
import Stores from "./components/Stores";
import Faq from "./components/Faq";
import Tnc from "./components/Tnc";
import StoresOnlineDelivery from "./components/StoresOnlineDelivery";
import StoresPickupOnStore from "./components/StoresPickupOnStore";
import ForgotPassword from "./components/ForgotPassword";
import AccountReviews from "./components/AccountReviews";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Notifications from "./components/Notifications";



export default () => {
    const access_token = localStorage.getItem('access_token');
    if (!empty(access_token)) {
        axios.defaults.headers.common['Authorization'] = access_token;
        store.dispatch(signIn());
    }else{
        store.dispatch(signOut());
    }

    axios.defaults.headers.common['Authorization-Type'] = 'USER';
    axios.defaults.headers.common['Device'] = 'WEB';
    axios.defaults.headers.common['App-Version'] = APP_VERSION;
    axios.defaults.headers.common['App-Build'] = BUILD_NUMBER;
    axios.defaults.headers.common['Brand'] = BRAND_CODE;

    return (
        <Provider store={store}>
            <IntlProvider translations={translations} locale='en'>
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Switch>
                            <Route exact path={`/shop/:category`} component={CategoryLanding}/>


                            <Layout>
                                <Route exact path={`/`} component={Home}/>
                                <Route path={`/sign-in`} component={Login}/>
                                <Route path={`/sign-up`} component={Register}/>
                                <Route path={`/forgot-password`} component={ForgotPassword}/>
                                <Route path={`/product/:id`} component={NoSideBar}/>
                                <Route exact path={`/products`} component={CollectionLeftSidebar}/>
                                <Route exact path={`/cart`} component={Cart}/>
                                <Route exact path={`/checkout`} component={Checkout}/>
                                <Route exact path={`/payment/response`} component={PaymentResponse}/>
                                <Route exact path={`/fashion`} component={Fashion}/>
                                <Route exact path={`/hashtag`} component={Hashtag}/>
                                <Route exact path={`/stores/online-delivery`} component={StoresOnlineDelivery}/>
                                <Route exact path={`/stores/pickup-on-store`} component={StoresPickupOnStore}/>
                                <Route exact path={`/stores`} component={Stores}/>
                                <Route exact path={`/faq`} component={Faq}/>
                                <Route exact path={`/tnc`} component={Tnc}/>
                                <Route exact path={`/notifications`} component={Notifications}/>
                                <Route exact path={`/privacy-policy`} component={PrivacyPolicy}/>
                                <Route exact path={`/delete-account`} component={DeleteAccount}/>
                                <Route exact path={`/account/change-password`} component={AccountChangePassword}/>
                                <Route exact path={`/account/shipping-address`} component={AccountShippingAddress}/>
                                <Route exact path={`/account/orders`} component={AccountOrders}/>
                                <Route exact path={`/account/reviews`} component={AccountReviews}/>
                                <Route exact path={`/account/wishlist`} component={AccountWishlist}/>
                                <Route exact path={`/account`} component={Account}/>
                            </Layout>
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </IntlProvider>
        </Provider>
    );
};