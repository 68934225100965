import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import styles from './Notifications.module.scss';
import ModuleNewsletter from "./ModuleNewsletter";
import {apiCall} from "../helpers/networkHelper";
import {USER_NOTIFICATIONS} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import {connect} from "react-redux";
import moment from "moment/moment";
import {nl2brReact} from "../helpers/stringHelper";

class Notifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            notifications: null
        }
    }

    componentDidMount() {
        if (this.props.authenticated) {
            apiCall(
                USER_NOTIFICATIONS + '?limit=20',
                'get',
                null,
                res => {
                    this.setState({
                        notifications: res.data.user_notifications
                    }, () => {
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                },
                res => {
                }
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Hammer | PT Warna Mardhika</title>
                    <meta name="description"
                          content="HAMMER was launched as the first brand of PT WARNA MARDHIKA in 1987. With characteristic lines, varying colors and bold, making HAMMER very famous among celebrities, models, and Indonesians."/>
                </Helmet>

                <section className={styles.titleSection}>
                    <h1>Notifications</h1>
                    <p>notifikasi dan status terkini</p>
                </section>

                <section className={`container ${styles.notificationContainer}`}>
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.notifications && (
                            <div className={styles.notificationList}>
                                <ul>
                                    {this.state.notifications.map(notification => (
                                        <li key={notification.id}>
                                            <a href={notification.href}>
                                                <span className={styles.notificationHeader}>{notification.title}</span>
                                                <span
                                                    className={styles.notificationBody}>{nl2brReact(notification.body)}</span>
                                                <span
                                                    className={styles.notificationTime}>{moment(notification.created_at).fromNow()}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                                <div className={styles.notificationAction}>
                                    <a href="/notifications">Mark all as read</a>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </section>

                <ModuleNewsletter/>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated
});

export default connect(mapStateToProps, null)(Notifications);